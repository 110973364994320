<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Users' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap items-center flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name"></b-input>
              </b-field>
              <b-field
                label="Email"
                :type="$store.getters.hasErrors('email').type"
                :message="$store.getters.hasErrors('email').message"
              >
                <b-input v-model="email"></b-input>
              </b-field>
              <b-field label="Role">
                <b-select
                  v-model="role_id"
                  placeholder="Assign a role"
                  expanded
                >
                  <option
                    v-for="(role, index) in roles"
                    :key="index"
                    :value="role.id"
                  >
                    {{ role.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                v-if="$route.params.id"
                label="New Password"
                :type="$store.getters.hasErrors('password').type"
                :message="$store.getters.hasErrors('password').message"
              >
                <b-input v-model="password"></b-input>
              </b-field>
              <b-field
                v-else
                label="Password"
                :type="$store.getters.hasErrors('password').type"
                :message="$store.getters.hasErrors('password').message"
              >
                <b-input v-model="password"></b-input>
              </b-field>
              <p class="heading">Additonal Client Settings</p>
              <div class="border p-4 mb-4">
                <b-field label="Require Secondary Login">
                  <b-radio
                    v-model="is_client"
                    :native-value="true"
                    type="is-success"
                    >Yes</b-radio
                  >
                  <b-radio
                    v-model="is_client"
                    :native-value="false"
                    type="is-success"
                    >No</b-radio
                  >
                </b-field>
                <b-field label="Price Factor Increase">
                  <b-numberinput
                    v-model="price_factor"
                    :min="1.0"
                    :step="0.5"
                    :editable="false"
                    type="is-success"
                    controls-position="compact"
                  ></b-numberinput>
                </b-field>
              </div>
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
export default {
  components: {
    Sidebar
  },
  data: () => ({
    name: "",
    email: "",
    role_id: "",
    password: "",
    is_client: false,
    price_factor: 1.0,
    roles: []
  }),
  computed: {},
  methods: {
    onSave() {
      store.dispatch("saveUsersForm", {
        name: this.name,
        email: this.email,
        id: this.$route.params.id || false,
        role_id: this.role_id,
        password: this.password || undefined,
        is_client: this.is_client,
        price_factor: this.price_factor
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this user?`,
        ids: [this.$route.params.id],
        path: "users",
        push: "Users"
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id || null })
        .then(res => {
          next(vm => {
            vm.roles = res.data.roles;
            if (to.params.id) {
              vm.name = res.data.user.name;
              vm.email = res.data.user.email;
              vm.role_id = res.data.user.role_id;
              vm.is_client = res.data.user.is_client;
              vm.price_factor = res.data.user.price_factor;
            }
          });
        });
    }
  }
};
</script>
